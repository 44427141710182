import {
	formatGenres,
	formatEventState,
	formatAvailabilityDate,
} from '@/lib/formaters';

/**
 *
 * @param event
 * @param locale The current locale code
 * @returns
 */
export const formatEventCalendar = (datas, locale = 'en-US') => {
	const eventDate = new Date(datas.date.replace(/-/g, '/')); // `.replace(/-/g, '/')` is to fix safari which fails parsing xxxx-xx-xx date format
	const now = new Date();
	const endDate = new Date(datas.event.endDate.replace(/-/g, '/')); // `.replace(/-/g, '/')` is to fix safari which fails parsing xxxx-xx-xx date format

	const availability = formatAvailability(
		datas.state,
		datas.availabilityLevel
	);

	return {
		id: datas.event.id,
		artist: datas.event.uptitle,
		title: datas.event.title,
		link: datas.event.eventCustomFields?.hasDetailPage
			? datas.event.link
			: null,
		time:
			`${eventDate.getHours()}${eventDate.getMinutes()}` === '00'
				? null
				: eventDate.toLocaleTimeString(locale, {
						hour: '2-digit',
						minute: '2-digit',
				  }),
		duration: datas.event.eventCustomFields.duration,
		location: datas?.location?.name ?? '',
		genres: formatGenres(
			datas.event.genres.nodes
				.map(({ translation: t }) => t)
				.filter(Boolean),
			datas.event.eventCustomFields.specialMention,
			!!datas.event.eventCustomFields.production?.length
		),
		availability,
		availability_date:
			availability === 'soon'
				? formatAvailabilityDate(datas.availabilityDate, locale)
				: null,
		date: eventDate,
		ticketsLink:
			endDate.getTime() >= now.getTime()
				? datas.uri || datas.event.eventCustomFields.ticketLink
				: null,
		ticketButtonText:
			datas?.uriLabel && datas.uriLabel !== ''
				? datas.uriLabel
				: datas.event.eventCustomFields.ticketButtonText ?? null,
		state: formatEventState(datas.state),
		isEvent: datas.event.eventCustomFields.isEvent,
		isAroundTheShow: datas.event.eventCustomFields.isAroundTheShow,

		// Needed for filters
		thematics: datas.event.thematics.nodes.map(({ translation: t }) => t),
		seasons: datas.event.seasons.nodes.map(({ translation: t }) => t),
		publics:
			datas.event.eventCustomFields.publics?.nodes.map(
				({ id, title, icon: { img } }) => ({
					id,
					title,
					icon: !!img && {
						src: img.src,
						alt: img.altText,
						width: img.mediaDetails?.node.width,
						height: img.mediaDetails?.node.height,
					},
				})
			) ?? [],
	};
};

/**
 * Format availability
 * @param state
 * @returns 'high' | 'low' | 'none' | 'soon'
 */
export const formatAvailability = (state, level) => {
	switch (state) {
		case 'ON_SALE':
		case 'RUNNING':
		case 'VALIDATED':
			switch (level) {
				case 'GOOD':
					return 'high';

				case 'LIMITED':
					return 'low';

				case 'NONE':
				default:
					return 'none';
			}
		case 'SOON_ON_SALE':
			return 'soon';
		default:
			return 'none';
	}
};

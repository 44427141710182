/**
 * Format event state
 * @param state
 * @returns 'canceled' | 'postponed'
 */
export const formatEventState = (state) => {
	switch (state) {
		case 'CANCELED':
		case 'SUSPENDED':
		case 'CANCELED_CLOSED':
			return 'canceled';
		case 'MOVED_OR_POSTPONED':
			return 'postponed';
	}
};

import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';

const getDynamicStyles = (styles) =>
	cssObj({
		backgroundColor: styles.backgroundColor,

		'.supt-section--section-ctas': {
			backgroundColor: styles.backgroundColor,
		},

		'.supt-section--section-ctas + .supt-listCards': {
			paddingTop: '20px',
		},
	});

const getStyles = (styles) => css(getDynamicStyles(styles), {});

export { getStyles };

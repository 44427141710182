import cx from 'classnames';
import { useContext, useState, useEffect, useMemo, Fragment } from 'react';

import { useHandleInternalLinks } from '@superhuit/starterpack-hooks';
import { SectionProvider, ThemeContext } from '@superhuit/starterpack-context';
import { omit } from '@superhuit/starterpack-utils';
import { LocaleContext } from '@superhuit/starterpack-i18n';
import Blocks from '@/next-components/blocks';
import Container from '@/next-components/container';
import { Filters, ResultsInfo, ListShowsEvents } from '@/components/renders';
import { formatEventCard, formatFilters } from '@/lib/formaters';
import { sortTerms } from '@/lib/sort-terms';
import { SeasonPageThemeControls } from './theming';
import { getStyles } from './styles.css';

const ARRAY_SIZE = 6;

export default function SeasonPage({ node }) {
	const blocks = node?.blocksJSON ? JSON.parse(node.blocksJSON) : [];
	const blocksCta = blocks.filter(({ name }) => name === 'supt/section-ctas');

	const handleInternalLinks = useHandleInternalLinks();

	// Merge: defaults + theme (variant base + variant active)
	const { theme } = useContext(ThemeContext);
	const blockTheme = theme?.['templates']?.[SeasonPageThemeControls.slug]; // theme
	const finalTheme = omit(blockTheme, ['variants']);
	// console.log('::: Final Theme ::: ', finalTheme);

	const rootClass = cx('supt-seasonPage', getStyles(finalTheme));

	const { __t, locale } = useContext(LocaleContext);

	const [selectedFilters, setSelectedFilters] = useState([]); // Selected filters to filter the list with

	/**
	 * Format Cards + filters
	 */
	const { formatedEvents, filters } = useMemo(() => {
		const events = [];
		const genres = {},
			thematics = {};
		// publics = {};

		if (node.archive?.events?.nodes) {
			node.archive.events.nodes.forEach((evt) => {
				events.push(formatEventCard(evt, locale));
				evt.genres.nodes.forEach((t) => {
					if (!genres[t.id]) genres[t.id] = t;
				});
				evt.thematics.nodes.forEach((t) => {
					if (!thematics[t.id]) thematics[t.id] = t;
				});
				// evt.publics.nodes.forEach((t) => {
				// 	if (!publics[t.id]) publics[t.id] = t;
				// });
			});
		}

		return {
			formatedEvents: events,
			filters: [
				{
					filter: 'genres',
					items: formatFilters(Object.values(genres).sort(sortTerms)),
				},
				{
					filter: 'thematics',
					items: formatFilters(
						Object.values(thematics).sort(sortTerms),
						true
					),
					link: node?.siteSettings?.pageForThematics
						? {
								href: node.siteSettings.pageForThematics.uri,
								title: __t(
									'thematics-filters-link',
									'Discover the themes'
								),
						  }
						: null,
				},
				// {
				// 	filter: 'publics',
				// 	items: formatFilters(
				// 		Object.values(publics).sort(sortTerms),
				// 		true
				// 	),
				// },
			],
		};
	}, [node.archive?.events]);

	useEffect(() => {
		if (location.search === '') return;

		const searchParams = new URLSearchParams(location.search);
		const preSelectedFilters = [];

		searchParams.forEach((value, key) => {
			value.split(',').forEach((id) => {
				preSelectedFilters.push({
					filter: key,
					...(filters
						.find(({ filter }) => filter === key)
						?.items?.find((item) => item.id === id) ?? {}),
				});
			});
		});
		setSelectedFilters(preSelectedFilters);
	}, []);

	/**
	 * Filter events
	 */
	const filteredEvents = useMemo(() => {
		if (!formatedEvents.length) return [];
		if (!selectedFilters.length) return formatedEvents;

		const selectedGFilters = selectedFilters.filter(
			({ filter }) => filter === 'genres'
		);
		const selectedTFilters = selectedFilters.filter(
			({ filter }) => filter === 'thematics'
		);
		// const selectedPFilters = selectedFilters.filter(
		// 	({ filter }) => filter === 'publics'
		// );

		return formatedEvents.filter((event) => {
			const hasGenres = !!selectedGFilters.length
				? event.genres.some(({ id }) =>
						selectedGFilters.find((g) => g.id === id)
				  )
				: true;
			const hasThematics = !!selectedTFilters.length
				? event.thematics.some(({ id }) =>
						selectedTFilters.find((t) => t.id === id)
				  )
				: true;
			// const hasPublics = !!selectedPFilters.length
			// 	? event.publics.some(({ id }) =>
			// 			selectedPFilters.find((p) => p.id === id)
			// 	  )
			// 	: true;

			return hasGenres && hasThematics /* && hasPublics */;
		});
	}, [formatedEvents, selectedFilters]);

	/**
	 * Update URL according to selected filters
	 */
	useEffect(() => {
		const queryArgs = selectedFilters.reduce((query, item) => {
			if (!query[item.filter]) query[item.filter] = [];
			query[item.filter].push(item.id);
			return query;
		}, {});

		const searchString = decodeURIComponent(
			new URLSearchParams(queryArgs).toString()
		);
		if (searchString !== location.search) {
			history.pushState(
				queryArgs,
				null,
				location.origin +
					location.pathname +
					(searchString !== '' ? '?' + searchString : '')
			);
		}
	}, [selectedFilters]);

	return (
		<>
			<Container className={rootClass} data-block="seasonPage">
				<div
					className="supt-seasonPage__inner"
					onClick={handleInternalLinks}
				>
					<SectionProvider variant="default">
						<Blocks
							blocks={blocks}
							includes={/^supt\/section-rich-content/g}
						/>
						<Filters
							title={node.title}
							buttons={node.seasonPageCTAs}
							filters={filters}
							selectedFilters={selectedFilters}
							onChange={(value) => setSelectedFilters(value)}
						/>

						{/* List of events */}
						{filteredEvents.length > 0 ? (
							blocksCta.length > 0 ? (
								blocksCta.map((cta, index) => {
									return (
										<Fragment key={index}>
											<ListShowsEvents
												events={filteredEvents.slice(
													index * ARRAY_SIZE,
													blocksCta.length ===
														index + 1
														? filteredEvents.length +
																1
														: index * ARRAY_SIZE +
																ARRAY_SIZE
												)}
											/>
											<Blocks
												blocks={[cta]}
												includes={
													/^supt\/section-ctas/g
												}
											/>
										</Fragment>
									);
								})
							) : (
								<ListShowsEvents events={filteredEvents} />
							)
						) : (
							<ResultsInfo
								number={0}
								onChange={(value) => setSelectedFilters(value)}
							></ResultsInfo>
						)}
					</SectionProvider>
				</div>
			</Container>
		</>
	);
}

const isSameYear = (date1, date2) =>
	date1.getFullYear() === date2.getFullYear();

export const formatAvailabilityDate = (dte, locale) => {
	if (!dte) return null;
	const availabilityDate = new Date(dte);
	const availabilityDateFormat: Intl.DateTimeFormatOptions = {
		day: '2-digit',
		month: '2-digit',
	};
	if (!isSameYear(availabilityDate, new Date()))
		availabilityDateFormat.year = '2-digit';

	return availabilityDate.toLocaleDateString(
		`${locale}-CH`,
		availabilityDateFormat
	);
};

export type FILTER_TERM = {
	id: number;
	name: string;
	uri?: string;
	slug?: string;
	description?: string;
	acf?: {
		link?: string;
	};
};

/**
 * Format "usualy" WP terms props into filters
 * @param filters
 * @param isSquared
 * @returns
 */
export const formatFilters = (filters: Array<FILTER_TERM>, isSquared = false) =>
	filters.map(({ id, name, description, acf }) => ({
		id,
		name,
		desc: description,
		isSquared,
		link: acf?.link,
	}));

import { uid } from 'uid';

/**
 * Format Genre for Event Calendar
 * @param genres
 * @param specialMention
 * @returns
 */
export const formatGenres = (genres, specialMention, isVidyProduction) => {
	let formattedGenres = [];

	if (genres?.length) {
		formattedGenres = genres.map((genre) => ({
			id: genre.id,
			title: genre.name,
		}));
	}

	if (specialMention) {
		formattedGenres.push({
			id: uid(),
			title: specialMention,
			type: 'exclusive',
		});
	}

	if (isVidyProduction) {
		formattedGenres.push({
			id: uid(),
			title: 'event-production-vidy',
			type: 'vidy',
		});
	}

	return formattedGenres;
};

import { formatGenres, formatEventState } from '@/lib/formaters';

const getTicketLink = (event) => {
	const nowTime = new Date().getTime();
	const endDate = new Date(event.endDate.replace(/-/g, '/')); // `.replace(/-/g, '/')` is to fix safari which fails parsing xxxx-xx-xx date format

	if (endDate.getTime() < nowTime) return null;

	let href = null,
		title = null;

	if (event.eventCustomFields.ticketLink)
		href = event.eventCustomFields.ticketLink;
	else {
		event.eventCustomFields?.datesEvent?.forEach((perf) => {
			if (!href) {
				const perfEndDate = new Date(perf.date.replace(/-/g, '/'));
				if (perfEndDate.getTime() > nowTime && perf.ticketLink) {
					href = perf.ticketLink;
				}
			}
		});
	}

	if (href && event.eventCustomFields.ticketButtonText)
		title = event.eventCustomFields.ticketButtonText;

	return {
		href,
		title,
	};
};

/**
 *
 * @param event
 * @param locale The current locale code
 * @returns
 */
export const formatEventCard = (event, locale = 'en-US') => {
	const data = {
		id: event.id,
		artist: event.uptitle,
		title: event.title,
		link: event.eventCustomFields.hasDetailPage
			? {
					href: event.link,
			  }
			: null,
		linkTicket: getTicketLink(event),
		isEvent: event.eventCustomFields.isEvent,
		isAroundTheShow: event.eventCustomFields.isAroundTheShow,
		text: event.excerpt,
		state: formatEventState(event.eventCustomFields.state),
		location: event?.locations?.nodes?.[0]?.name ?? '',
		image: event.image
			? {
					src: event.image.node.relativeUrl,
					alt: event.image.node.altText,
					width: event.image.node.mediaDetails.width,
					height: event.image.node.mediaDetails.height,
			  }
			: null,
		publics:
			event.eventCustomFields.publics?.nodes.map(
				({ id, title, icon: { img } }) => ({
					id,
					title,
					icon: !!img && {
						src: img.src,
						alt: img.altText,
						width: img.mediaDetails?.node.width,
						height: img.mediaDetails?.node.height,
					},
				})
			) ?? [],
		// Needed for filters
		genres: formatGenres(
			event.genres.nodes,
			event.eventCustomFields.specialMention,
			!!event.eventCustomFields.production?.length
		),
		thematics: event.thematics.nodes,
		// publics: event.publics.nodes,
		seasons: event.seasons.nodes,
		dates: event.datesString,
	};

	return data;
};
